"use client";

import { Star, Quote } from "lucide-react";
import { motion } from "framer-motion";

const testimonials = [
  {
    name: "Nguyễn Văn A",
    role: "AI Engineer",
    content:
      "Dịch vụ Cloud GPU của Thuegpu.vn đã giúp chúng tôi tăng tốc quá trình training model AI lên gấp 10 lần. Thực sự ấn tượng!",
    rating: 5,
  },
  {
    name: "Trần Thị B",
    role: "Game Developer",
    content:
      "Tôi đã sử dụng nhiều dịch vụ Cloud GPU, nhưng Thuegpu.vn là tốt nhất. Hiệu suất cao, giá cả hợp lý, và hỗ trợ kỹ thuật tuyệt vời.",
    rating: 5,
  },
  {
    name: "Lê Văn C",
    role: "Data Scientist",
    content:
      "Thuegpu.vn giúp tôi tiết kiệm rất nhiều thời gian trong việc huấn luyện các mô hình machine learning. Dịch vụ rất ổn định và đáng tin cậy.",
    rating: 5,
  },
  {
    name: "Phạm Thị D",
    role: "3D Artist",
    content:
      "Render video và mô hình 3D nhanh chóng với GPU mạnh mẽ. Tôi rất hài lòng với chất lượng dịch vụ của Thuegpu.vn.",
    rating: 5,
  },
];

export function Testimonials() {
  return (
    <section className="relative z-10 py-20 px-4 md:px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-white/70 text-center mb-16">
          Khách hàng nói gì về chúng tôi
        </h2>

        {/* Bento Grid Layout */}
        <div className="grid grid-cols-1 gap-6">
          {/* First Row: 8-4 */}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
            {/* Featured Testimonial 1 - Large */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="md:col-span-8 bg-gradient-to-br from-white/[0.03] to-white/[0.01] backdrop-blur-sm border border-white/10 rounded-2xl p-8 flex flex-col justify-between group hover:bg-white/[0.05] transition-all duration-500 hover:shadow-2xl hover:shadow-primary/5"
            >
              <div>
                <Quote className="w-12 h-12 text-primary mb-6 opacity-80" />
                <p className="text-xl md:text-2xl text-white/90 mb-8 leading-relaxed">
                  {testimonials[0].content}
                </p>
                <div className="flex items-center space-x-1.5 mb-6">
                  {[...Array(testimonials[0].rating)].map((_, i) => (
                    <Star key={i} className="w-6 h-6 fill-primary text-primary" />
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-5">
                  <div className="w-14 h-14 rounded-full bg-gradient-to-br from-primary/30 to-primary/10 flex items-center justify-center text-xl font-bold text-white border border-primary/20">
                    {testimonials[0].name[0]}
                  </div>
                </div>
                <div>
                  <h4 className="text-white text-lg font-semibold">{testimonials[0].name}</h4>
                  <p className="text-white/70">{testimonials[0].role}</p>
                </div>
              </div>
            </motion.div>

            {/* Testimonial 2 - Small */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="md:col-span-4 bg-gradient-to-br from-white/[0.03] to-white/[0.01] backdrop-blur-sm border border-white/10 rounded-2xl p-6 flex flex-col justify-between group hover:bg-white/[0.05] transition-all duration-500 hover:shadow-xl hover:shadow-primary/5"
            >
              <div>
                <Quote className="w-8 h-8 text-primary/70 mb-4" />
                <p className="text-white/90 mb-6 text-lg leading-relaxed">{testimonials[1].content}</p>
                <div className="flex items-center space-x-1 mb-4">
                  {[...Array(testimonials[1].rating)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 fill-primary text-primary" />
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary/30 to-primary/10 flex items-center justify-center text-lg font-bold text-white border border-primary/20">
                    {testimonials[1].name[0]}
                  </div>
                </div>
                <div>
                  <h4 className="text-white font-semibold">{testimonials[1].name}</h4>
                  <p className="text-white/60 text-sm">{testimonials[1].role}</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Second Row: 4-8 */}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
            {/* Testimonial 3 - Small */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="md:col-span-4 bg-gradient-to-br from-white/[0.03] to-white/[0.01] backdrop-blur-sm border border-white/10 rounded-2xl p-6 flex flex-col justify-between group hover:bg-white/[0.05] transition-all duration-500 hover:shadow-xl hover:shadow-primary/5"
            >
              <div>
                <Quote className="w-8 h-8 text-primary/70 mb-4" />
                <p className="text-white/90 mb-6 text-lg leading-relaxed">{testimonials[2].content}</p>
                <div className="flex items-center space-x-1 mb-4">
                  {[...Array(testimonials[2].rating)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 fill-primary text-primary" />
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary/30 to-primary/10 flex items-center justify-center text-lg font-bold text-white border border-primary/20">
                    {testimonials[2].name[0]}
                  </div>
                </div>
                <div>
                  <h4 className="text-white font-semibold">{testimonials[2].name}</h4>
                  <p className="text-white/60 text-sm">{testimonials[2].role}</p>
                </div>
              </div>
            </motion.div>

            {/* Featured Testimonial 4 - Large */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="md:col-span-8 bg-gradient-to-br from-white/[0.03] to-white/[0.01] backdrop-blur-sm border border-white/10 rounded-2xl p-8 flex flex-col justify-between group hover:bg-white/[0.05] transition-all duration-500 hover:shadow-2xl hover:shadow-primary/5"
            >
              <div>
                <Quote className="w-12 h-12 text-primary mb-6 opacity-80" />
                <p className="text-xl md:text-2xl text-white/90 mb-8 leading-relaxed">
                  {testimonials[3].content}
                </p>
                <div className="flex items-center space-x-1.5 mb-6">
                  {[...Array(testimonials[3].rating)].map((_, i) => (
                    <Star key={i} className="w-6 h-6 fill-primary text-primary" />
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="mr-5">
                  <div className="w-14 h-14 rounded-full bg-gradient-to-br from-primary/30 to-primary/10 flex items-center justify-center text-xl font-bold text-white border border-primary/20">
                    {testimonials[3].name[0]}
                  </div>
                </div>
                <div>
                  <h4 className="text-white text-lg font-semibold">{testimonials[3].name}</h4>
                  <p className="text-white/70">{testimonials[3].role}</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
